<template>
  <Notifications />
</template>

<script>
import Notifications from "@voerro/vue-notifications";

export default {
  name: "notify.vue",
  components: { Notifications },
  data() {
    return {
      notifyArr: []
    };
  },
  mounted() {
    const ztx = this;
    const socket = new WebSocket("wss://api.roobo.pro/websocket");
    console.log("socket!!!!");
    this.socket = socket;
    this.socket.onopen = function() {
      console.log("socket!!!!OPEM");
      const auth = {
        method: "auth",
        token: localStorage.getItem("token")
      };
      // socket.send(JSON.stringify(auth));
      socket.send(
        JSON.stringify({
          event: "auth",
          data: JSON.stringify(auth)
        })
      );
    };
    this.socket.onclose = function(event) {
      if (event.wasClean) {
        console.log("Соединение закрыто чисто");
      } else {
        console.log("Обрыв соединения"); // например, "убит" процесс сервера
      }
      console.log("Код: " + event.code + " причина: " + event.reason);
    };
    this.socket.onmessage = function(event) {
      console.log("MEESSS");
      const mes = JSON.parse(event.data);
      console.log(mes);
      if (mes.event == "notify") {
        ztx.notifyArr.push(mes.data);
        // eslint-disable-next-line no-undef
        notify({
          position: 'top-right',
          text: "<span style='text-align: left'>\n" +
            "    <b>"+mes.data.tittle+"</b>\n" +
            "</span>\n" +
            "<br>\n" +
            "<br>\n" + " <span style='text-align: left'> "+ mes.data.text+"</span> ",
          theme: "ocean-blue",
        });

        const audio = new Audio('https://roobo.pro/z_uk-budte-vkontakte-s-no_ostyami.mp3');
        audio.play();
        // ztx.$notification(
        //   mes.data.tittle,
        //   mes.data.text,
        //   'defualt',
        //   async () => {
        //     window.location.href
        //   },
        //   'A minute ago'
        // )

      }
    };
  }
};
</script>

<style>
.voerro-notification-theme-ocean-blue {
  border: solid 1px #c2c2c2;
  border-radius: 10px;
  text-align: left;
  background: #ffffff;
  color: #282828;
}
</style>
