  <template>

  <div id="app">
    <Notify />
    <router-view />
    <footer v-if="$route.meta.hideFooter" id="footer22">
      <div
        style="display: flex;flex-direction: column;justify-content: space-between;background-color: rgb(33, 31, 31);">
        <div class="logotype">
          <div class="logimg">
            <img src="../src/assets/logotip.png" alt="" style="width: 100%;margin-top: 35px;">
          </div>
          <div class="log_cards">
            <img src="../src/assets/national-payment-system-mir.svg" alt="">
            <img src="../src/assets/8408-visa_102499.svg" alt="">
            <img src="../src/assets/mastercard_old_payment_method_icon_142751.svg" alt=""">
          </div>
        </div>
        <div class="fote">
          <div class="footes">
            <div class="ips">
              <h2 style="color: #d8d7d7;">Владелец</h2>
              <p>ИП Мялкин Александр Сергеевич</p>
              <p>ИНН: 772409837853</p>
            </div>
            <div class="sogla">
              <h2 style="color: #d8d7d7;">Документы</h2>
              <p><a href="/info" style="color: white;text-decoration: none;">Политика конфиденциальности</a> &ensp;</p>
              <p><a href="/info" style="color: white;text-decoration: none;">Пользовательское соглашение</a>&ensp;<a
                href=""></a></p>
              <p><a href="/info/subs" style="color: white;text-decoration: none;">Соглашение с подпиской</a></p>
              <p><a href="/info" style="color: white;text-decoration: none;">Правила сервиса и рекуррентные платежи</a>
              </p>
            </div>
            <div class="soglas">
              <h2 style="display: inline; border-bottom: 2px solid #a09696;">Свяжитесь с нами</h2>
              <p style="margin-top: 15px;">info@roobo.pro</p>
              <p>+79175418593</p>
              <p style="padding-right: 40px">Звонить с 12:00 <br> до 24:00 по МСК</p>
            </div>
          </div>
        </div>

      </div>
    </footer>

  </div>

</template>

<script>
import Notify from "@/components/notify.vue";

export default {
  name: "appMain",
  components:{Notify},
}
</script>
<style lang="scss">
.log_cards {
  display: flex;
  margin-right: 50px;
}
.log_cards > img {
  width: 100px;
  margin-top: 15px;
}
#app {
  overflow: hidden;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#footer {
  opacity: 0;
  -moz-animation: cssAnimation 4s;
  /* Firefox */
  -webkit-animation: cssAnimation 4s;
  /* Safari and Chrome */
  -o-animation: cssAnimation 4s;
  /* Opera */
  animation: cssAnimation 4s;
  transition: all 4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes cssAnimation {
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes cssAnimation {
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

footer {
  font-size: 16px;
  text-decoration: none;
  position: relative;
  top: 100%;
}

.fote {
  padding-bottom: 25px;
}

.footes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.ips > p {
  color: white;
}

.btn-info {
  background-color: #fe6637;
  text-decoration: none;
  display: inline-block;
  padding: 5px 10px;
  position: relative;
  overflow: hidden;
  border: 1px solid #fe6637;
  border-radius: 6px;
  font-weight: 650;
  font-family: "Montserrat", sans-serif;
  color: white;
  transition: 0.5s ease-in-out;
}

.btn-info:hover {
  border: 1px solid #ff4911;
  background: #ff4911;
  color: rgb(255, 255, 255);
}

.btn-info:before {
  content: "";
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.5)
  );
  height: 50px;
  position: absolute;
  top: -8px;
  left: -75px;
  transform: skewX(-45deg);
}

.btn-secondary {
  background-color: #fe6637;
  text-decoration: none;
  display: inline-block;
  padding: 5px 10px;
  position: relative;
  overflow: hidden;
  border: 1px solid #fe6637;
  border-radius: 6px;
  font-weight: 650;
  font-family: "Montserrat", sans-serif;
  color: white;
  transition: 0.5s ease-in-out;
}

.btn-secondary:hover {
  border: 1px solid #ff4911;
  background: #ff4911;
  color: rgb(255, 255, 255);
}

.btn-secondary:before {
  content: "";
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.5)
  );
  height: 50px;
  position: absolute;
  top: -8px;
  left: -75px;
  transform: skewX(-45deg);
}

.btn-secondary-outline {
  background-color: #fe6637;
  text-decoration: none;
  display: inline-block;
  padding: 5px 10px;
  position: relative;
  overflow: hidden;
  border: 1px solid #fe6637;
  border-radius: 6px;
  font-weight: 650;
  font-family: "Montserrat", sans-serif;
  color: white;
  transition: 0.5s ease-in-out;
}

.btn-secondary-outline:hover {
  border: 1px solid #ff4911;
  background: #ff4911;
  color: rgb(255, 255, 255);
}

.btn-secondary-outline:before {
  content: "";
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.5)
  );
  height: 50px;
  position: absolute;
  top: -8px;
  left: -75px;
  transform: skewX(-45deg);
}

.btn-primary {
  background-color: #fe6637;
  text-decoration: none;
  display: inline-block;
  padding: 5px 10px;
  position: relative;
  overflow: hidden;
  border: 1px solid #fe6637;
  border-radius: 6px;
  font-weight: 650;
  font-family: "Montserrat", sans-serif;
  color: white;
  transition: 0.5s ease-in-out;
}

.btn-primary:hover {
  border: 1px solid #ff4911;
  background: #ff4911;
  color: rgb(255, 255, 255);
}

.btn-primary:before {
  content: "";
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.5)
  );
  height: 50px;
  position: absolute;
  top: -8px;
  left: -75px;
  transform: skewX(-45deg);
}

.btn-primary-outline {
  background-color: #fe6637;
  text-decoration: none;
  display: inline-block;
  padding: 5px 10px;
  position: relative;
  overflow: hidden;
  border: 1px solid #fe6637;
  border-radius: 6px;
  font-weight: 650;
  font-family: "Montserrat", sans-serif;
  color: white;
  transition: 0.5s ease-in-out;
}

.btn-primary-outline:hover {
  border: 1px solid #ff4911;
  background: #ff4911;
  color: rgb(255, 255, 255);
}

.btn-primary-outline:before {
  content: "";
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.5)
  );
  height: 50px;
  position: absolute;
  top: -8px;
  left: -75px;
  transform: skewX(-45deg);
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-button {
  background-color: #666;
}

::-webkit-scrollbar-track {
  background-color: #999;
}

::-webkit-scrollbar-track-piece {
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #666;
  border-radius: 3px;
}

::-webkit-scrollbar-corner {
  background-color: #999;
}

::-webkit-resizer {
  background-color: #666;
}

.logotype {
  display: flex;
  padding-left: 30px;
  justify-content: space-between;
}

.ips {
  padding-top: 20px;
  padding-left: 200px;
  text-align: left;
}

.sogla {
  padding-top: 20px;
  text-align: left;
  padding-left: 150px;
}

.soglas {
  padding-top: 20px;
  text-align: right;
  padding-right: 50px;
  color: white;
}

.soglas > p {
  font-size: 25px;
}

@media only screen and (max-width: 600px) {
  .footes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .ips {
    padding-left: 0%;
    padding-right: 60px;
  }
  .sogla {
    padding-left: 0%;
  }
  .soglas {
    padding-right: 110px;
    text-align: left;
  }
  .soglas > h2 {
    font-size: 20px;
  }
  .soglas > p {
    font-size: 20px;
  }
  .logotype {
    display: flex;
    justify-content: center;
  }
}
@media only screen and (max-width: 820px) {
  .log_cards {
    margin-right: 0px;
  }
  .log_cards>img {
    width: 70px;
  }
}
@media only screen and (max-width: 690px) {
  .logotype {
    flex-direction: column;
  }
  .log_cards>img {
    margin-top: 0;
  }
}
</style>

